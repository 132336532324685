export const MIGInputField = {
  rental: [
    { value: "Local", text: "Local" },
    { value: "Slab Rate", text: "Slab Rate" },
    { value: "Out Station", text: "Out Station" },
    { value: "Airport Transfer", text: "Airport Transfer" },
    { value: "Hotel Transfer", text: "Hotel Transfer" },
    { value: "Flat Rate", text: "Flat Rate" },
    { value: "Trip rate", text: "Trip rate" },
    { value: "Shift Rate", text: "Shift Rate" },
    { value: "Day Rate", text: "Day Rate" },
    { value: "Escort Airport Transfer", text: "Escort Airport Transfer" },
    { value: "One Way", text: "One Way" },
    { value: "Two Way", text: "Two Way" },
    { value: "Railway Bus Transfer", text: "Railway Bus Transfer" },
    { value: "KMS Rate", text: "KMS Rate" },
  ],
  selectedRentalType: "",
  selectedClient: "",
  selectedClientLocation: "",
  selectedFromAndToDate: [],
  position: 1,
};
