export const tarrifInputField = {
  rental: [
    { value: "Local", text: "Local" },
    { value: "Slab Rate", text: "Slab Rate" },
    { value: "Out Station", text: "Out Station" },
    { value: "Airport Transfer", text: "Airport Transfer" },
    { value: "Hotel Transfer", text: "Hotel Transfer" },
    { value: "Flat Rate", text: "Flat Rate" },
    { value: "Trip rate", text: "Trip rate" },
    { value: "Shift Rate", text: "Shift Rate" },
    { value: "Day Rate", text: "Day Rate" },
    { value: "Escort Airport Transfer", text: "Escort Airport Transfer" },
    { value: "One Way", text: "One Way" },
    { value: "Two Way", text: "Two Way" },
    { value: "Railway Bus Transfer", text: "Railway Bus Transfer" },
    { value: "KMS Rate", text: "KMS Rate" },
  ],
  selectedRental: "Local",
  segment: [
    { value: "a/c", text: "a/c" },
    { value: "non a/c", text: "non a/c" },
  ],
  selectedSegment: "",
  selectedArea: "",
  slabhrs: [
    { value: 1, text: "1 Hours" },
    { value: 2, text: "2 Hours" },
    { value: 3, text: "3 Hours" },
    { value: 4, text: "4 Hours" },
    { value: 5, text: "5 Hours" },
    { value: 6, text: "6 Hours" },
    { value: 8, text: "8 Hours" },
    { value: 10, text: "10 Hours" },
    { value: 12, text: "12 Hours" },
    { value: 13, text: "13 Hours" },
    { value: 16, text: "16 Hours" },
  ],
  selectedSlabhrs: "",
  slabkms: [
    { value: 0, text: "0 Kms" },
    { value: 10, text: "10 Kms" },
    { value: 20, text: "20 Kms" },
    { value: 40, text: "40 Kms" },
    { value: 50, text: "50 Kms" },
    { value: 60, text: "60 Kms" },
    { value: 80, text: "80 Kms" },
    { value: 85, text: "85 Kms" },
    { value: 96, text: "96 Kms" },
    { value: 100, text: "100 Kms" },
    { value: 120, text: "120 Kms" },
    { value: 130, text: "130 Kms" },
    { value: 140, text: "140 Kms" },
    { value: 200, text: "200 Kms" },
    { value: 210, text: "210 Kms" },
    { value: 250, text: "250 Kms" },
    { value: 300, text: "300 Kms" },
    { value: 350, text: "350 Kms" },
    { value: 400, text: "400 Kms" },
  ],
  selectedSlabkms: "",
  slabfrom: [
    { value: 0, text: 0 },
    { value: 1, text: 1 },
    { value: 6, text: 6 },
    { value: 11, text: 11 },
    { value: 16, text: 16 },
    { value: 21, text: 21 },
    { value: 26, text: 26 },
    { value: 31, text: 31 },
    { value: 36, text: 36 },
    { value: 41, text: 41 },
    { value: 46, text: 46 },
    { value: 51, text: 51 },
    { value: 56, text: 56 },
    { value: 61, text: 61 },
    { value: 66, text: 66 },
    { value: 71, text: 71 },
    { value: 76, text: 76 },
    { value: 81, text: 81 },
  ],
  selectedSlabfrom: null,
  slabto: [
    { value: 5, text: 5 },
    { value: 10, text: 10 },
    { value: 15, text: 15 },
    { value: 20, text: 20 },
    { value: 25, text: 25 },
    { value: 30, text: 30 },
    { value: 35, text: 35 },
    { value: 40, text: 40 },
    { value: 45, text: 45 },
    { value: 50, text: 50 },
    { value: 55, text: 55 },
    { value: 60, text: 60 },
    { value: 65, text: 65 },
    { value: 70, text: 70 },
    { value: 75, text: 75 },
    { value: 80, text: 80 },
    { value: 85, text: 85 },
    { value: 86, text: "Above" },
  ],
  selectedSlabto: null,
  addon: [
    { value: "single", text: "Single" },
    { value: "escort", text: "Escort" },
    { value: "single_long", text: "Single_Long" },
    { value: "back_to_back", text: "Back_to_Back" },
  ],
  selectedAddon: "",
  salesRate: null,
  purchaseRate: null,
  salesExKmsRate: null,
  salesExHrsRate: null,
  purchaseExHrsRate: null,
  purchaseExKmsRate: null,
  purchaseGraceTime: null,
  salesGraceTime: null,
  driverbata: null,
  position: 1,
};
export const onCallMisrequiredFields = [
  "Date",
  "Dutyslip_No",
  "Vehicle_No",
  "Vehicle_Billed_As",
  "Segment",
  "OnCall_Rental",
  "Company_Name",
];
// export const tarrifExcelUploadrequiredFields = [
//   "S.no",
//   "Company Name",
//   "Vehicle Type",
//   "Rental",
//   "Segment",
// ];
export const slabExcelUploadrequiredFields = [
  "Date",
  "Dutyslip_No",
  "Vehicle_No",
  "Company_Name",
  "Slab_Rental",
  "Vehicle_Billed_As",
  "Segment",
];
export const tripExcelUploadrequiredFields = [
  "Date",
  "Dutyslip_No",
  "Vehicle_No",
  "Company_Name",
  "Vehicle_Billed_As",
  "Segment",
  "Trip_Rental",
];
export const dayExcelUploadrequiredFields = [
  "Date",
  "Dutyslip_No",
  "Vehicle_No",
  "Vehicle_Billed_As",
  "Segment",
  "Company_Name",
  "Day_Rental",
];
